<template>
  <div
    class="login-container"
    :style="'background-image: url(' + backgroundImageSrc + ')'"
  >
    <div class="login-header">
      <div
        class="logo"
        :style="'background-image: url(' + mainlogoSrc + ')'"
      ></div>
      <!-- <div class="logo-tab">
        <div class="logo-tab-select"><a target="_blank" href="https://www.wisharetec.com">关于慧享</a></div>
        <div class="login-divider"></div>
        <div class="logo-tab-select" @click="onAbout">联系我们</div>
        <div class="login-divider"></div>
        <div class="logo-tab-select" @click="projectDialog = true">项目入驻</div>
      </div> -->
    </div>

    <div class="content-wrapper">
      <!-- <div class="content-bg" :style="'background-image: url(' + mainBgSrc + ')'">

      </div> -->
      <!-- 账号密码、手机验证码登录 -->
      <div class="main-login-wrapper">
        <div v-if="!scanCodeLogin" class="login-wrapper">
          <div class="login-tabs-wrapper">
            <div
              class="tab-item-wrapper"
              v-for="(title, index) in loginTabs"
              :key="index"
              @click="tabClick(index)"
            >
              <div
                :class="[
                  'tab-title',
                  loginType - 1 === index ? 'tab-title-active' : ''
                ]"
              >
                {{ title }}
              </div>
              <div
                :class="[
                  'tab-bottom-line',
                  loginType - 1 === index ? 'tab-active' : ''
                ]"
              ></div>
            </div>
          </div>
          <el-form ref="form" :model="form">
            <v-input style="position:fixed;bottom: -9999px;" />
            <v-input type="password" style="position:fixed;bottom: -9999px;" />
            <div v-if="loginType === 1" class="login-input-wrapper">
              <el-form-item
                class="v-input-wrapper"
                prop="account"
                :rules="[
                  { required: true, message: '请输入用户名', trigger: 'change' }
                ]"
              >
                <v-input
                  ref="account"
                  placeholder="输入用户名"
                  v-model="form.account"
                  :width="inputWidth"
                  @keyup.enter.native="loginAction"
                ></v-input>
              </el-form-item>
              <el-form-item
                class="v-input-wrapper"
                prop="password"
                :rules="[
                  { required: true, message: '请输入密码', trigger: 'change' }
                ]"
              >
                <v-input
                  ref="password"
                  placeholder="输入密码"
                  v-model="form.password"
                  type="password"
                  :width="inputWidth"
                  @keyup.enter.native="loginAction"
                ></v-input>
              </el-form-item>
            </div>
          </el-form>
          <el-form ref="form" :model="form">
            <div v-if="loginType === 2" class="login-input-wrapper">
              <el-form-item
                class="v-input-wrapper"
                prop="phone"
                :rules="[
                  {
                    required: true,
                    message: '请输入手机号',
                    trigger: 'change'
                  },
                  { validator: validatePhone, trigger: 'change' }
                ]"
              >
                <!-- <input
                  tabindex="-1"
                  value="1"
                  type="password"
                  style="position:fixed;bottom:-99999px;"
                /> -->
                <v-input
                  ref="phone"
                  :maxlength="11"
                  placeholder="输入手机号"
                  v-model="form.phone"
                  :width="inputWidth"
                  @keyup.enter.native="loginAction"
                ></v-input>
              </el-form-item>
              <el-form-item
                :class="[
                  'input-wrapper',
                  validateCodeError ? 'input-error-wrapper' : '',
                  isFocus ? 'input-wrapper-active' : ''
                ]"
                prop="code"
                :rules="[
                  {
                    required: true,
                    message: '请输入验证码',
                    trigger: 'change'
                  },
                  { validator: validateCode, trigger: 'change' }
                ]"
              >
                <!-- <input
                  tabindex="-1"
                  value="1"
                  type="password"
                  style="position:fixed;bottom:-99999px;"
                /> -->
                <v-input
                  ref="verification"
                  :maxlength="6"
                  placeholder="输入验证码"
                  v-model="form.code"
                  :width="verificationWidth"
                  @blur="codeBlur"
                  @focus="codeFocus"
                  @keyup.enter.native="loginAction"
                ></v-input>
                <div
                  :class="[
                    'send-code-button',
                    sendDisabled || !canSendCode ? 'send-disabled-text' : ''
                  ]"
                  @click="sendCode"
                >
                  {{ sendText }}
                </div>
              </el-form-item>
            </div>
          </el-form>
          <v-button
            v-if="loginType !== 3"
            class="login-button"
            text="登录"
            :width="inputWidth"
            :disabled="disabled"
            @click="loginAction"
          ></v-button>
          <div class="login-qrcode-wrapper" v-if="loginType === 3">
            <div
              class="login-qrcode"
              v-loading="loginQrcodeStatus === 'CONFIRM_LOGIN'"
              element-loading-background="rgba(255, 255, 255, 0.95)"
            >
              <div id="login_qrcode_img" class="login_img"></div>
              <div
                v-if="
                  loginQrcodeStatus === 'QR_EXPIRED' ||
                    loginQrcodeStatus === 'SCAN_SUCCESS'
                "
                class="login_img_cover"
              ></div>
              <img
                v-if="loginQrcodeStatus === 'QR_EXPIRED'"
                src="./images/scan_refresh.png"
                class="icon-refresh"
                @click="scanRefresh"
              />
            </div>

            <span
              v-if="loginQrcodeStatus === 'CONFIRM_LOGIN'"
              class="qrcode-footer-logining"
              >正在登陆…</span
            >
            <span
              v-if="
                loginQrcodeStatus === 'CREATE_SUCCESS' ||
                  loginQrcodeStatus === 'QR_EXPIRED'
              "
              class="qrcode-footer"
              >请使用管理端APP扫码二维码登录</span
            >

            <div
              v-if="loginQrcodeStatus === 'SCAN_SUCCESS'"
              class="scan-tip-wrapper"
            >
              <img src="./images/scan_login_scaned.png" class="scan-tip-icon" />
              <div class="scan-tip-content-wrapper">
                <span class="scan-tip-title">扫描成功</span>
                <span class="scan-tip-content">请在APP中点击确认即可登录</span>
              </div>
            </div>
            <div
              v-if="loginQrcodeStatus === 'CANCEL_LOGIN'"
              class="scan-tip-wrapper"
            >
              <img src="./images/scan_login_cancel.png" class="scan-tip-icon" />
              <div class="scan-tip-content-wrapper">
                <span class="scan-tip-title">您已取消此次登录</span>
                <span class="scan-tip-content">您可再次扫描登录，或关闭窗口</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="footer">
      <div class="copyright">
        <span v-text="copyright"></span>
        |
        <a
          class="beian-link"
          href="http://www.beian.miit.gov.cn"
          target="_blank"
          v-text="icpNO"
        ></a>
      </div>
    </div> -->
    <el-dialog
      :width="dialogWidth"
      custom-class="aboutDialog"
      :visible.sync="aboutVisible"
      @closed="closeAboutDialog"
    >
      <div class="dialog-qrcode" >
        <h4>
          浙江慧享信息科技有限公司
        </h4>
        <span>
          智慧社区全域服务赋能者
        </span>
        <div class="dialog-text">
          <div>地址: 浙江省杭州市西湖文一西路767号绿城西溪国际商务中心B座</div>
          <table>
            <tr>
              <th>销售支持</th>
              <th>运维支持</th>
            </tr>
            <tr>
              <td>销售热线：19967314695</td>
              <td>服务热线：0571-87095567</td>
            </tr>
            <tr>
              <td>邮<i>空格</i>箱：libingyi@lvchengfuwu.com</td>
              <td>邮<i>空格</i>箱：gaoxiaoxian@lvchengfuwu.com</td>
            </tr>
          </table>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :width="dialogWidth"
      custom-class="projectDialog"
      :visible.sync="projectDialog"
      @closed="projectDialog = false"
    >
     <project-enter @submit1="submit1"></project-enter>
    </el-dialog>
    <el-dialog
      :width="dialogWidth"
      custom-class="successDialog"
      :visible.sync="successDialog"
      @closed="successDialog = false"
    >
     <div class="successDialog" style="background:none" @click="successDialog = false"></div>
    </el-dialog>
    <!-- 预加载一张背景图 -->
    <img style="display: none" src="./images/project_bg.png" alt="">
  </div>
</template>

<script>
import crypto from 'crypto'
import { _localStorage, sessionStorageHelper } from 'common/utils'
import { mobileRegular, verifyCodeRegular } from 'common/regular'
import QRCode from 'qrcodejs2'
import projectEnter from './projectEnter.vue'

const defaultBackgroundImageSrc = require('./images/login_bg7.png')
const defaultMainlogoSrc = require('./images/main_logo7.png')
const defaultMainBgSrc = require('./images/main_bg.png')

export default {
  name: 'login',
  data () {
    return {
      projectDialog: false,
      successDialog: false,
      disabled: false,
      account: '',
      password: '',
      // 扫描登录
      scanCodeLogin: false,
      // 1账号密码 2验证码  3扫码
      loginType: 1,
      loginTabs: ['密码登录', '手机号登录', '扫码登录'],
      sendText: '获取验证码',
      sendDisabled: false,
      form: {
        account: '',
        password: '',
        phone: '',
        code: ''
      },
      sendCodeTimer: undefined,
      qrCodeTimer: undefined,
      qrCodeTime: 10,
      time: 60,
      validateCodeError: false,
      qrCode: '',
      loginQrcodeStatus: 'QR_EXPIRED', // CREATE_SUCCESS, QR_EXPIRED, CANCEL_LOGIN ,SCAN_SUCCESS ,CONFIRM_LOGIN
      webSocketIndex: 0,
      webSocket: null,
      isFocus: false,
      aboutVisible: false

    }
  },
  components: {
    'projectEnter': projectEnter
  },
  mounted () {
    console.log(document.body.clientWidth)
    console.log(document.body.offsetWidth)
    this.dealWithQRCodeShow()
  },
  computed: {
    inputWidth () {
      let inputWidths = 370
      if (document.body.clientWidth < 1441 || document.body.offsetWidth < 1441) {
        inputWidths = 322
      } else {
        inputWidths = 370
      }
      return inputWidths
    },
    verificationWidth () {
      let verificationWidths = 238
      if (document.body.clientWidth < 1441 || document.body.offsetWidth < 1441) {
        verificationWidths = 198
      } else {
        verificationWidths = 238
      }
      return verificationWidths
    },
    dialogWidth () {
      let dialogWidths = '980px'
      if (document.body.clientWidth < 1441 || document.body.offsetWidth < 1441) {
        dialogWidths = '880px'
      } else {
        dialogWidths = '980px'
      }
      return dialogWidths
    },
    backgroundImageSrc () {
      // return this.$store.state.baseConfig.backgroundImageSrc || defaultBackgroundImageSrc
      return defaultBackgroundImageSrc
    },
    mainlogoSrc () {
      // return this.$store.state.baseConfig.mainlogoSrc || defaultMainlogoSrc
      return defaultMainlogoSrc
    },
    mainBgSrc () {
      return this.$store.state.baseConfig.mainBgSrc || defaultMainBgSrc
      // return defaultMainBgSrc
    },
    copyright () {
      console.log(this.$store.state.baseConfig.copyright)
      // return this.$store.state.baseConfig.copyright;
      return this.$store.state.baseConfig.icpNO && this.$store.state.baseConfig.icpNO.split('|')[0]
    },
    icpNO () {
      return this.$store.state.baseConfig.icpNO && this.$store.state.baseConfig.icpNO.split('|')[1]
    },

    canSendCode () {
      let regExp = mobileRegular
      return regExp.test(this.form.phone)
    }
  },
  // 离开页面时，需要清除的一些东西在此操作
  destroyed () {
    clearInterval(this.sendCodeTimer)
    this.dealSetQRCodeExpired()
  },
  created () {
    // 进入登录页面，清空token
    this.$axios.defaults.headers.token = ''
    _localStorage.removeItem('token')

    document.title = '智慧文旅全域服务平台'
  },
  methods: {
    submit1 () {
      this.projectDialog = false
      this.successDialog = true
    },
    loginAction () {
      let _this = this
      let props = this.loginType === 1 ? ['account', 'password'] : ['phone', 'code']
      let canContinue = true
      this.$refs.form.validateField(props, errorMessage => {
        if (errorMessage && errorMessage.length) {
          if (
            errorMessage === '请输入验证码' ||
            errorMessage === '您填写验证码格式不正确'
          ) {
            _this.validateCodeError = true
          } else {
            if (_this.validateCodeError) {
              _this.validateCodeError = false
            }
          }
          canContinue = false
        }
      })
      if (!canContinue) {
        return
      }

      let account = this.loginType === 1 ? this.form.account : this.form.phone
      let password =
        this.loginType === 1
          ? crypto
            .createHash('md5')
            .update(this.form.password)
            .digest('hex')
          : this.form.code
      let mode = this.loginType === 1 ? 'PASSWORD' : 'VERIFICATION'
      // 密码登录PASSWORD 验证码登录VERIFICATION
      let postData = {
        username: account,
        password: password,
        mode: mode
      }
      this.requestForLogin(postData)
    },

    requestForLogin (pramas) {
      let _this = this
      _this.disabled = true
      _this
        .$axios({
          method: 'post',
          url: `${API_CONFIG.butlerBaseURL}login`,
          data: this.$qs.stringify(pramas)
        })
        .then(async res => {
          _this.disabled = false
          if (res.status === 100) {
            let data = res.data
            let companyCode = data.companyCode
            let subPlatformId = data.subPlatformId
            let childPlatformId = data.childPlatformId
            let availablePlatformIds = data.availablePlatformIds
            if (!availablePlatformIds || !availablePlatformIds.length) {
              _this.$message({
                type: 'error',
                message: '子平台权限出错了, 联系管理员',
                center: true
              })
              return
            }

            _localStorage.setItem('companyCode', companyCode)
            let token = data.token
            if (token && token.length > 0) {
              _this.$axios.defaults.headers.token = token
              _this.$store.commit('setToken', token)
              _localStorage.setItem('token', token)
            }
            const getCookie = await this.getCookie()
            console.log(getCookie, '--不要删除--')
            _this.$router.push({
              name: 'home',
              query: {
                login: 1,
                cid: childPlatformId,
                pid:
                  subPlatformId === 0 ? availablePlatformIds[0] : subPlatformId,
                newLogin: 7
              }
            })
          } else {
            _this.password = ''
            _this.$refs.password.focus()
            if (pramas && pramas.mode === 'QR') {
              _this.loginQrcodeStatus = 'QR_EXPIRED'
              _this.dealSetQRCodeExpired()
            }
          }
        })
        .catch(res => {
          if (pramas && pramas.mode === 'QR') {
            _this.loginQrcodeStatus = 'QR_EXPIRED'
            _this.dealSetQRCodeExpired()
          }
        })
    },
    async getCookie () {
      let res = await this.$axios.get(`${API_CONFIG.baseURL}userAction!getCookie.action`)
      console.log(res, '用于老接口请求时携带的cookie能生效,不要删除')
    },
    tabClick (index) {
      this.$refs.form && this.$refs.form.clearValidate()
      if (this.validateCodeError) {
        this.validateCodeError = false
      }
      this.loginType = index + 1
      if (this.loginType === 3) {
        this.scanRefresh()
      }
    },

    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      if (!regExp.test(value)) {
        callback(new Error('您填写手机号码格式不正确'))
      } else {
        callback()
      }
    },

    validateCode (rule, value, callback) {
      let regExp = verifyCodeRegular
      if (!regExp.test(value)) {
        this.validateCodeError = true
        callback(new Error('您填写验证码格式不正确'))
      } else {
        this.validateCodeError = false
        callback()
      }
    },

    async sendCode () {
      if (this.sendDisabled || !this.canSendCode) {
        return
      }
      let postData = {
        type: 7,
        phone: this.form.phone
      }
      const { status } = await this.$axios({
        method: 'post',
        url: `${API_CONFIG.butlerBaseURL}common/sendCode`,
        headers: {
          mobileType: 999
        },
        data: this.$qs.stringify(postData)
      })

      if (status === 100) {
        this.sendDisabled = true
        this.startTimer()
        this.$message.success('验证码发送成功')
      }
    },

    startTimer () {
      let _this = this
      this.sendCodeTimer = setInterval(() => {
        if (_this.time <= 0) {
          _this.time = 60
          _this.sendText = '重新获取'
          _this.sendDisabled = false
          clearInterval(_this.sendCodeTimer)
        } else {
          _this.sendText = `${_this.time}s后重发`
          _this.time--
        }
      }, 1000)
    },

    // 验证码输入框失焦处理
    codeBlur (event) {
      if (this.form.code.length === 0) {
        this.validateCodeError = true
      }
      this.isFocus = false
    },
    // 验证码输入框获取焦点处理
    codeFocus () {
      this.isFocus = true
    },
    // 创建二维码
    async dealWithQRCodeShow () {
      const { data } = await this.$axios({
        method: 'get',
        url: `${API_CONFIG.butlerBaseURL}manager/qrLogin/code`
      })
      if (!data) {
        return
      }
      this.qrCode = data.qrCode
      this.qrCodeTime = data.validityPeriod
      this.createSocket()
      let dom = document.getElementById('login_qrcode_img')
      if (dom) {
        while (dom.firstChild) {
          dom.removeChild(dom.firstChild)
        }
        let encryCode = data.qrCode
        if (encryCode && encryCode.length) {
          let id = `login_qrcode_img`
          this.$nextTick(() => {
            let QRCodeWidth = 196
            let QRCodeheight = 196
            if (document.body.clientWidth < 1441 || document.body.offsetWidth < 1441) {
              QRCodeWidth = 160
              QRCodeheight = 160
            } else {
              QRCodeWidth = 196
              QRCodeheight = 196
            }
            new QRCode(id, {
              // eslint-disable-line
              width: QRCodeWidth,
              height: QRCodeheight,
              text: encryCode,
              correctLevel: 3
            })
          }, 20)
          this.loginQrcodeStatus = 'CREATE_SUCCESS'
          this.startQrCodeTimer()
        }
      }
    },
    startQrCodeTimer () {
      let _this = this
      let time = _this.qrCodeTime || 0
      this.qrCodeTimer = setInterval(() => {
        if (time <= 0) {
          if (_this.loginQrcodeStatus !== 'CONFIRM_LOGIN') {
            _this.loginQrcodeStatus = 'QR_EXPIRED'
          }
          clearInterval(_this.qrCodeTimer)
          this.webSocket && this.webSocket.close()
        } else {
          time--
        }
      }, 1000)
    },

    getWebSocketUri () {
      let webSocketUri = ''
      const currentUrl = API_CONFIG.butlerBaseURL.substr(
        0,
        API_CONFIG.butlerBaseURL.indexOf('manage')
      )
      const wsProtocol = location.protocol === 'https:' ? 'wss://' : 'ws://'
      webSocketUri = `${wsProtocol}${currentUrl}socket`
      webSocketUri = webSocketUri.replace(/(https|http):\/\//g, '')
      return webSocketUri
    },
    createSocket () {
      // 初始化weosocket
      /* eslint-disable */
      const wsuri = this.getWebSocketUri();
      this.webSocket = new WebSocket(wsuri);
      this.webSocket.onopen = this.webSocketOnOpen;
      this.webSocket.onmessage = this.webSocketOnMessage;
      this.webSocket.onerror = this.webSocketOnError;
      this.webSocket.onclose = this.webSocketClose;
    },
    webSocketOnOpen() {
      // 连接建立之后执行send方法发送数据
      let data = {
        code: "11000002",
        body: { qrCode: this.qrCode || "" }
      };
      this.webSocketIndex = 0;
      this.webSocketSend(JSON.stringify(data));
    },
    webSocketOnError() {
      // 连接建立失败重连
      this.webSocketIndex++;
      if (this.webSocketIndex < 15) {
        this.createSocket();
      }
    },
    webSocketOnMessage(e) {
      // 数据接收
      const readData = JSON.parse(e.data);
      if (!readData || !readData.body) {
        return;
      }
      this.loginQrcodeStatus = readData.body && readData.body.action;
      if (
        readData.body &&
        readData.body.code &&
        readData.body.action === "CONFIRM_LOGIN"
      ) {
        let pramas = {
          username: readData.body.code,
          mode: "QR"
        };
        this.requestForLogin(pramas);
      }
    },
    webSocketSend(Data) {
      // 数据发送
      this.webSocket.send(Data);
    },
    scanRefresh() {
      this.dealWithQRCodeShow();
    },
    dealSetQRCodeExpired() {
      this.qrCodeTimer && clearInterval(this.qrCodeTimer);
      this.webSocket && this.webSocket.close();
    },
    enterClick() {
      console.log("enter 点击");
    },
    onAbout () {
      this.aboutVisible = true;
    },
    closeAboutDialog () {
      this.aboutVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.login-container {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .login-header {
    position: absolute;
    // top: 50px;
    // left: 50%;
    // transform: translate(-50%, 0%);
    // top: 104px;
    top: 50px;
    left: 50px;
    // width: 1340px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      display: inline-block;
      // width: 230px;
      // height: 72px;
      // width: 390px;
      // height: 80px;
      width: 350px;
      height: 70px;
      // background-image: url('./images/main_logo.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      // margin-bottom: 64px;
    }
    .logo-tab {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      .logo-tab-select {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
      .logo-tab-select:hover {
        font-weight: 500;
      }
      .login-divider {
        width: 1px;
        height: 15px;
        background: #ffffff;
        margin: 0 20px;
      }
    }
  }

  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    .content-bg {
      width: 860px;
      height: 480px;
      // background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .login-divider {
      width: 1px;
      height: 200px;
      background: #ffffff;
      margin: 0 100px;
    }
    .login-qrcode {
      width: 196px;
      height: 196px;
      // margin: 50px auto;
      margin: 35px auto;
      padding: 15px;
      border-radius: 4px;
      background-color: #ffffff;
      // background: rgba(255, 255, 255, 1);
      border-radius: 4px;
      display: flex;
      font-size: 40px;
      color: #a2a0a0;
      justify-content: center;
      align-items: center;
      .login_img_cover {
        position: absolute;
        width: 196px;
        height: 196px;
        // background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        opacity: 0.95;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon-refresh {
        width: 76px;
        height: 76px;
        position: absolute;
      }
    }
    .qrcode-footer-logining {
      font-size: 24px;
      // font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
    .qrcode-footer {
      font-size: 16px;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }
  .main-login-wrapper {
    width: 480px;
    height: 480px;
    background: rgba(255, 255, 255, 0.1233);
    border-radius: 6px;
    overflow: hidden;

    .login-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: column;

      .login-tabs-wrapper {
        margin-top: 50px;
        width: 370px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid #D7D7D7;
        .tab-item-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          // width: 120px;

          .tab-title {
            font-size: 20px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 33px;
            color: #F7F7F7;
            text-align: center;
            letter-spacing: 2px;
          }

          .tab-title-active {
            // font-family: PingFangSC-Medium, PingFang SC;
            color: #fff;
          }
          .tab-bottom-line {
            width: 100%;
            margin-top: 10px;
            height: 4px;
            overflow: hidden;
            margin-bottom: -2px;
          }

          .tab-active {
            background: #fff;
          }
          &:hover {
             .tab-title {
              font-weight: 500;
              // font-family: "黑体";
             }

          }
        }

      }
      .v-input-wrapper {
        margin: 35px auto;
        width: 370px;

        ::v-deep .el-input__inner {
          height: 60px;
          font-size: 18px;
          font-weight: 400;
          border: 1px solid #3788A1;
          background: rgba(255, 255, 255, 0.8);
          color: #5B90A0;
          &:hover {
            background: rgba(255, 255, 255, 1)
          }
        }
      }

      ::v-deep .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */
        color: #5B90A0;
      }
      ::v-deep .el-input__inner:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        // color: rgba(255, 255, 255, 0.5);
        color: #5B90A0;
      }
      ::v-deep .el-input__inner::-moz-placeholder { /* Mozilla Firefox 19+ */
        // color: rgba(255, 255, 255, 0.5);
        color: #5B90A0;
      }
      ::v-deep .el-input__inner:-ms-input-placeholder { /* Internet Explorer 10+ */
        // color: rgba(255, 255, 255, 0.5);
        color: #5B90A0;
      }

      ::v-deep .el-form-item__error {
        padding-top: 8px;
        font-size: 18px;
        font-weight: 400;
      }
      .input-wrapper {
        box-sizing: border-box;
        margin: 35px auto;
        width: 370px;
        height: 60px;
        border-radius: 3px;
        border: 1px solid #3788A1;
        background: rgba(255, 255, 255, 0.8);
        &:hover {
          background: rgba(255, 255, 255, 1)
        }
        color: #5B90A0;
        ::v-deep .el-form-item__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        ::v-deep .el-input__inner {
          margin-top: 1px;
          margin-left: 1px;
          height: 58px;
          // height: 100%;
          font-size: 18px;
          font-weight: 400;
          color: #5B90A0;
          border: none;
          outline: none;
          background: transparent;
        }

        .send-code-button {
          flex-shrink: 0;
          width: 100px;
          margin-right: 20px;
          color: #00A1FF;
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          cursor: pointer;
        }

        .send-disabled-text {
          color: #00A1FF;
          cursor: not-allowed;
        }
      }
      .input-wrapper-active {
        border: 1px solid #3788A1;
        background: rgba(255, 255, 255, 0.8);
        color: #5B90A0;
        ::v-deep .el-input__inner {
          margin-top: 1px;
          margin-left: 1px;
          // height: 60px;
          height: 58px;
          font-size: 18px;
          font-weight: 400;
          color: #5B90A0;
          border: none;
          outline: none;
          background: transparent;
        }
      }

      .login-button {
        height: 60px;
        margin-top: 30px;
        ::v-deep .el-button {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          box-shadow: 0 0 0px 1000px #0D96FF  inset !important;
          letter-spacing: 20px;
          padding-left: 20px;
        }
        ::v-deep .el-button:hover {
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          box-shadow: 0 0 0px 1000px #0086ED  inset !important;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    font-size: 12px;
    color: #fff;
    span {
      margin-right: 5px;
    }
    .beian-link {
      margin-left: 5px;
      text-decoration: none;
      &:link,
      &:visited,
      &:hover,
      &:active {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  .scan-tip-wrapper {
    display: flex;
    .scan-tip-icon {
      width: 56px;
      height: 56px;
    }
    .scan-tip-content-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 14px;
      .scan-tip-title {
        font-size: 22px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
      .scan-tip-content {
        font-size: 18px;
        text-align: left;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  ::v-deep .el-loading-spinner .path {
    width: 76px;
    height: 76px;
    stroke: #a2a0a0;
    stroke-width: 3;
  }
  ::v-deep .el-dialog__header {
    background-color: transparent;
    border-radius: 5px 5px 0;
  }
  ::v-deep .aboutDialog {
    height: 606px;
    border-radius: 5px;
    background: url("./images/about_bg.png") no-repeat center center;
    background-size: 100%;
  }
  ::v-deep .projectDialog {
    height: 606px;
    border-radius: 5px;
    background: url("./images/project_bg.png") no-repeat center center;
    background-size: 100%;
    // overflow-y: scroll;
  }
  ::v-deep .successDialog {
    height: 606px;
    border-radius: 5px;
    background: url("./images/success_bg.png") no-repeat center center;
    background-size: 100%;
  }
  .dialog-qrcode {
    text-align: left;
    padding: 0 41px;
    position: relative;
    h4 {
      font-size: 28px;
      color: #1A1A1A;
      margin-top: 20px;
      margin-bottom: 0;
    }
    span {
      font-size: 16px;
      color: #666666;
    }
    .dialog-text {
      color: #666666;
      font-size: 20px;
      width: 100%;
      margin-top: 180px;
      table {
        margin-top: 50px;
        tr {
          td {
            font-size: 18px;
            padding-right: 47px;
            padding: 5px 47px 0 0;
            i {
              color: transparent;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .login-header {
      position: absolute;
      top: 78px;
      left: 96px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        display: inline-block;
        // width: 230px;
        // height: 72px;
        width: 292px;
        height: 60px;
        // background-image: url('./images/main_logo.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        // margin-bottom: 64px;
      }
      .logo-tab {
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        .logo-tab-select {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          a {
            color: #ffffff;
            text-decoration: none;
          }
        }
        .logo-tab-select:hover {
          font-weight: 500;
        }
        .login-divider {
          width: 1px;
          height: 15px;
          background: #ffffff;
          margin: 0 20px;
        }
      }
    }
    .content-wrapper {
      .content-bg {
        width: 600px;
        height: 320px;
        background-size: contain;
      }
      .login-qrcode {
        width: 160px;
        height: 160px;
        margin: 30px auto;
        padding: 10px;
        border-radius: 4px;
        background-color: #ffffff;
        .login_img_cover {
          width: 160px;
          height: 160px;
        }
        .icon-refresh {
          width: 76px;
          height: 76px;
        }
      }
      .qrcode-footer-logining {
        font-size: 24px;
      }
      .qrcode-footer {
        font-size: 16px;
      }
    }
    .main-login-wrapper {
      width: 380px;
      height: 380px;
      .login-wrapper {
        .login-tabs-wrapper {
          margin-top: 20px;
          width: 320px;
          .tab-item-wrapper {
            .tab-title {
              font-size: 18px;
              line-height: 33px;
              letter-spacing: 2px;
            }
            .tab-bottom-line {
              width: 100%;
              margin-top: 10px;
              height: 4px;
              overflow: hidden;
              margin-bottom: -2px;
            }
          }
        }
        .v-input-wrapper {
          margin: 35px auto;
          width: 322px;

          ::v-deep .el-input__inner {
            height: 55px;
            font-size: 16px;
          }
        }
        ::v-deep .el-form-item__error {
          font-size: 14px;
        }
        .input-wrapper {
          margin: 30px auto;
          width: 322px;
          height: 55px;
          border-radius: 3px;
          ::v-deep .el-input__inner {
            margin-top: 1px;
            margin-left: 1px;
            height: 53px;
            font-size: 16px;
          }
          .send-code-button {
            flex-shrink: 0;
            width: 100px;
            margin-right: 20px;
            font-size: 16px;
          }
        }
        .input-wrapper-active {
          ::v-deep .el-input__inner {
            margin-top: 1px;
            margin-left: 1px;
            height: 55px;
            font-size: 16px;
          }
        }
        .login-button {
          height: 55px;
          margin-top: 15px;
          ::v-deep .el-button {
            font-size: 20px;
          }
          ::v-deep .el-button:hover {
            font-size: 20px;
          }
        }
      }
    }
    ::v-deep .el-loading-spinner .path {
      width: 76px;
      height: 76px;
      stroke: #a2a0a0;
      stroke-width: 3;
    }
    ::v-deep .el-dialog__header {
      background-color: transparent;
      border-radius: 5px 5px 0;
    }
    ::v-deep .aboutDialog {
      height: 545px;
      border-radius: 5px;
      background: url("./images/about_bg.png") no-repeat;
      background-size: 100%;
    }
    ::v-deep .projectDialog {
      height: 541px;
      border-radius: 5px;
      background: url("./images/project_bg.png") no-repeat;
      background-size: 100%;
    //   overflow-y: scroll;
    }
    ::v-deep .successDialog {
      height: 541px;
      border-radius: 5px;
      background: url("./images/success_bg.png") no-repeat;
      background-size: 100%;
    }
    .dialog-qrcode {
      padding: 0 40px;
      h4 {
        font-size: 26px;
        margin-top: 20px;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
      }
      .dialog-text {
        font-size: 18px;
        width: 100%;
        margin-top: 160px;
        table {
          margin-top: 40px;
          tr {
            td {
              font-size: 16px;
              padding-right: 47px;
              padding: 5px 47px 0 0;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-dialog__wrapper{
    z-index: 2900!important;
}
::v-deep .el-dialog__body{
    padding: 0;
}
</style>
